<template>
  <!--
    Used to display the intros and outros that are used in the themes of videoPostprocessing.
  -->
  <div class="introOutroList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(introOutros)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(introOutros)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Rename</span>
              </button>
              <router-link
                :to="`/videopostprocessing/introoutro/${ props.dataItem.id }`"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                  style="vertical-align: middle;"
                />
                <span>Intros/Outros</span>
              </router-link>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <IntroOutroAdd
          v-if="addingElement"
          :intro-outro-id="currentId"
          @reload="reloadIntroOutros"
        />
        <IntroOutroEdit
          v-else
          :intro-outro-id="currentId"
          @reload="reloadIntroOutros"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeIntroOutro()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import IntroOutroEdit from '@/components/VideoPostprocessing/IntroOutro/IntroOutroEdit.vue';
import IntroOutroAdd from '@/components/VideoPostprocessing/IntroOutro/IntroOutroAdd.vue';
import Sidebar from '@/components/Base/Sidebar.vue';

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "IntroOutroList",
  components: {
    Sidebar,
    IntroOutroEdit,
    IntroOutroAdd,
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      introOutros: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      deleteIndex: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '320px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('intro')
    }
  },
  created () {
    this.loadingIntroOutros();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentId(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentId (id) {
      this.currentId = id.toString();
    },
    //#endregion

    //#region API-calls
    loadingIntroOutros () {
      this.loading = true;
      this.getIntroOutros();
    },
    reloadIntroOutros () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getIntroOutros();
    },
    openConfirmModal (index) {
      this.deleteIndex = index;
      this.$refs.confirmDelete.open();
    },
    removeIntroOutro () {
      this.axios.delete('/VideoPostProcessing/DeleteIntroOutro?introOutroId=' + this.deleteIndex)
        .then(() => {
          this.$emit("reloadAuditLogs");
          this.reloadIntroOutros();
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getIntroOutros () {
      this.axios.get('/VideoPostProcessing/GetIntroOutros')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.introOutros = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    //#endregion
  }
}
</script>
<style>
.introOutroList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
.outroList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>