<template>
  <!--
    Used to add intro/outros to the videoPostprocessing theme.
  -->
  <div class="introOutroAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('introOutroAddComp.introOutro') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="introOutroName"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <span
          v-show="errors.has('Name')" 
          class="badge badge-danger"
        >{{ errors.first('Name') }}</span>
      </div>
      <button 
        class="btn btn-primary float-right" 
        @click.prevent="addIntroOutro()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "IntroOutroAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      introOutroName: ""
    }
  },
  methods: {
    addIntroOutro () {
      this.axios.put(`/VideoPostProcessing/AddIntroOutro`, { Name: this.introOutroName })
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('introOutroAddComp.addedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
