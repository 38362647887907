<template>
  <!--
    Used to edit the intro/outro that is used to hold the intros/outros of the videoPostprocessingTheme.
  -->
  <div class="introOutroEdit">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <template v-if="introOutro">
        <form>
          <div class="form-group">
            <h4 class="m-0 mt-3">
              {{ $t('introOutroEditComp.introOutro') }}
            </h4>
            <hr class="m-0 mb-3">
            <label>{{ $t('name') }}</label>
            <input
              v-model="introOutro.name"
              v-focus
              :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
              type="text"
            >
            <span
              v-show="errors.has('Name')"
              class="badge badge-danger"
            >{{ errors.first('Name') }}</span>
          </div>
          <button
            class="btn btn-sm btn-primary float-right"
            @click.prevent="updateIntroOutro()"
          >
            <font-awesome-icon
              class="mr-1"
              icon="check"
            />
            <span>
              {{ $t('save') }}
            </span>
          </button>
          <div class="clearfix" />
        </form>
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "IntroOutroEdit",
  mixins: [
    errorMixin
  ],
  props: {
    introOutroId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      introOutro: null
    }
  },
  watch:{
    introOutroId: function () {
      this.loadIntroOutro();
    }
  },
  created () {
    this.loadIntroOutro();
  },
  methods: {
    loadIntroOutro () {
      this.loading = true;
      this.axios.get('/VideoPostProcessing/GetIntroOutro?introOutroid=' + this.introOutroId)
        .then((response) => {
          this.introOutro = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateIntroOutro () {
      let introOutroDto = {
        'Id': parseInt(this.introOutroId),
        'Name': this.introOutro.name
      };
      this.axios.put('/VideoPostProcessing/UpdateIntroOutro', introOutroDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('introOutroEditComp.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
